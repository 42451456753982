body, html {
  background-color: #282c34;
  height: 100%;
  display: grid;
  color: white;
}

.Home {
  text-align: center;
}

/*
 * Added to overwrite CSS that bootstrap added
 */
.table>:not(:first-child) {
  border-top: 0px;
}

#root {
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
}

.navbar-light .navbar-nav .nav-link, .nav-link, a:link, a:visited, a:hover, a:active {
  color: burlywood;
}

.navbar-light .navbar-nav .nav-link.active {
  color: fuchsia;
}

.itemBox img {
  max-width: 100%;
  max-height: 100%;
}

.itemBoxClicked {
  background-color: teal;
}

button, input, optgroup, select, textarea {
  margin: 5px;
}

.App {
  text-align: center;
  height: 100%;
  width: 100%;
}

.header {
  max-height: 18vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 2vh;
}


.App-header {
  font-size: calc(10px + 2vmin);
}

.link-header {
  font-size: calc(10px + 1vmin);
}

.App-link {
  color: #61dafb;
}

.boardContainer {
  max-height: 78vh;
  align-items: center;
  text-align: center;
  table-layout: fixed;
}

.databaseResultContainer {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 50px;
}

.databaseResultContainer div:not(:last-child) {
  margin-right: 50px;
}

.databaseResults {
  display: inline-block;
  justify-content: center;
  align-items: center;
}

.boardArea {
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 80vh;
  width: 95vw;
  max-width: inherit;
  margin-left: auto;
  margin-right: auto;
  table-layout: fixed;
}

.table {
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 80vh;
  width: 95vw;
  margin-left: auto;
  margin-right: auto;
  color: white;
}

.tableRow {
  height: calc(80vh / 3);
  width: 95vw;
  float: left;
}

.tableCell {
  height: calc(80vh/3);
  width: calc(90vw / 3);
  float: left;
  border: 1px solid gray;
  cursor: pointer;
  flex-direction: column;
  display: flex;
}

.itemText {
  height: 10%;
}

.itemImage {
  height: 90%;
}

@media all and (min-width: 480px) {
  .Signup form, .Reset form, .ResetMfa form, .Login form {
    margin: 0 auto;
    max-width: 320px;
  }

  .Signup input, .Reset input, .ResetMfa input, .Login input {
    margin: 0 auto;
    max-width: 320px;
  }

  .Signup div, .Reset div, .ResetMfa div, .Login div {
    padding-bottom: 0.5vh;
    padding-top: 0.5vh;
  }
}

h3 {
  padding-bottom: 2vh;
}